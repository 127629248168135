import React, { useMemo, useEffect } from 'react';
import { trackPendoEvent } from 'utils/pendo';
import { EmptyStateTw } from '@hqo/react-components-library/dist/molecules/empty-state/emptyState';
import { ErrorStateComponent } from '../../styles';

interface EmptyWalletProps {
  title: string;
  body: string;
  errorIcon: string;
}
export const EmptyWallet = ({ title, body, errorIcon }: EmptyWalletProps): JSX.Element => {
  const descriptions = useMemo(() => [body], [body]);
  useEffect(() => {
    trackPendoEvent('wallet-not-found');
  }, []);

  return (
    <ErrorStateComponent data-cy="empty-wallet" data-testid="empty-wallet">
      <EmptyStateTw icon={errorIcon} title={title} descriptions={descriptions} />
    </ErrorStateComponent>
  );
};
