import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { AuthState } from './types';

export const INITIAL_STATE: AuthState = {
  loading: false,
  error: null,
};

const openAuthFlowRequestHandler = (state: AuthState) => ({
  ...state,
  loading: true,
});

const openAuthFlowSuccessHandler = (state: AuthState): AuthState => ({
  ...state,
  loading: false,
});

const openAuthFlowFailureHandler = (
  state: AuthState,
  { payload: error }: ActionType<typeof actions.openAuthFlow.failure>,
): AuthState => ({
  ...state,
  loading: false,
  error: error.error,
});

const AuthReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.openAuthFlow.request, openAuthFlowRequestHandler)
  .handleAction(actions.openAuthFlow.success, openAuthFlowSuccessHandler)
  .handleAction(actions.openAuthFlow.failure, openAuthFlowFailureHandler);

export default AuthReducer;
