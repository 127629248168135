import { createAsyncAction } from 'typesafe-actions';
import { ErrorDto } from '../errors/types';
import { Wallet, WalletReloadUrl } from './types';
import { ReloadParams, WalletsParams } from 'shared/types';

export const getWallets = createAsyncAction('GET_WALLETS_REQUEST', 'GET_WALLETS_SUCCESS', 'GET_WALLETS_FAILURE')<
  WalletsParams,
  Array<Wallet>,
  ErrorDto
>();

export const reloadWallet = createAsyncAction(
  'RELOAD_WALLET_REQUEST',
  'RELOAD_WALLET_SUCCESS',
  'RELOAD_WALLET_FAILURE',
)<ReloadParams, WalletReloadUrl, ErrorDto>();
