import styled from 'styled-components';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';

export const Container = styled.div`
  max-width: ${SMALL_DEVICE_MAX_WIDTH}px;
`;
export const WalletContainer = styled.div`
  padding: 50px 60px 25px 60px;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    padding: 20px 20px 10px 20px;
  }
  width: 100;
  background: ${({ theme }) => `linear-gradient(0deg, transparent 70%, ${theme.colors.$white} 30%)`};
  background-color: ${({ theme }) => theme.colors.greys.adminGreyLight};
`;
export const ReloadTitle = styled.h4`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 28px;
`;

export const ErrorStateComponent = styled.div`
  display: flex;
  padding: 10% 0%;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    padding: 50% 0%;
  }
  align-items: center;
  height: 20%;
  flex-direction: column;
  & svg {
    font-size: 4em;
  }
`;

export const ErrorStateWrapper = styled.div`
  padding: 1em;
`;

export const CheckoutButtonWrapper = styled.div`
  padding: 0px 10px;
  background: ${({ theme }) => theme.colors.$white};
`;
