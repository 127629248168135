import { AjaxResponse, ajax } from 'rxjs/ajax';

import { AltBuilding } from 'store/alt-buildings/types';
import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { CurrentUserResponse } from 'store/user/types';
import { ReloadWalletAPIResponse, WalletsAPIResponse } from 'store/wallets/types';
import { AuthURLResponse } from 'store/auth/types';
import { OwnerTypesEnum, WALLET_TYPES, VERTICAL_NAMES } from 'shared/consts';
import { OwnerParams, ReloadParams } from 'shared/types';
import { UiMetadata } from 'store/ui-metadata/types';
import { stringify } from 'qs';
import { Observable } from 'rxjs';
import { ApiRequestHeaders, JSON_CONTENT_TYPE } from 'store/api-client/api-client.consts';

export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private hqoBuildingUuid?: string | null,
    private altBuildingUuid?: string | null,
  ) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      [ApiRequestHeaders.CONTENT_TYPE]: JSON_CONTENT_TYPE,
      ...(this.authToken ? { [ApiRequestHeaders.AUTHORIZATION]: this.authToken } : {}),
      ...(this.hqoBuildingUuid ? { [ApiRequestHeaders.HQO_BUILDING_UUID]: this.hqoBuildingUuid } : {}),
      ...(this.altBuildingUuid ? { [ApiRequestHeaders.ALT_BUILDING_UUID]: this.altBuildingUuid } : {}),
    };
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/auth/current`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  getAltBuildings(buildingUuid: string): Observable<AjaxResponse<AltBuilding[]>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/alt_buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  getWallets(buildingUuid: string, walletTypes: Array<WALLET_TYPES>): Observable<AjaxResponse<WalletsAPIResponse>> {
    return ajax.get(
      `${this.baseUrl}/api/wallet/v1/wallets?${stringify(
        { building_uuid: buildingUuid, wallet_types: walletTypes },
        { arrayFormat: 'brackets' },
      )}`,
      this.DefaultHeaders,
    );
  }

  getAuthURL(helixAuthLink: string, ownerUuid: string): Observable<AjaxResponse<AuthURLResponse>> {
    const params = {
      owner_uuid: ownerUuid,
      owner_type: OwnerTypesEnum.BUILDING.toUpperCase(),
      redirect_url: window.location.origin,
      vertical: VERTICAL_NAMES.WALLET.toUpperCase(),
    };
    return ajax.post(`${this.baseUrl}${helixAuthLink}`, params, this.DefaultHeaders);
  }

  fetchUiMetadata({ ownerType, ownerId }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    return ajax.get(
      `${
        this.baseUrl
      }/api/verticals/${VERTICAL_NAMES.WALLET.toUpperCase()}/${ownerType.toUpperCase()}/${ownerId}/ui-metadata`,
      this.DefaultHeaders,
    );
  }

  reloadWallet({
    amount,
    buildingUuid,
    returnUri,
    walletType,
  }: ReloadParams): Observable<AjaxResponse<ReloadWalletAPIResponse>> {
    return ajax.post(
      `${this.baseUrl}/api/wallet/v1/wallets/${walletType}/${buildingUuid}/reload-url`,
      {
        amount,
        return_uri: returnUri,
      },
      this.DefaultHeaders,
    );
  }
}
