/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Container, ReloadTitle, CheckoutButtonWrapper, WalletContainer } from './styles';
import {
  CurrencyEnum,
  LocaleEnum,
  WALLET_TYPES,
  RELOAD_AMOUNTS,
  ERROR_PATH,
  EXTERNAL_AUTH_TOKEN_NOT_FOUND,
  ACTION_STATUSES,
  EMPTY_WALLET,
  FontLocation,
  LDFlags,
} from 'shared/consts';
import { getWallets, reloadWallet } from 'store/wallets/actions';
import { ReloadButton } from './components/reloadButton';
import { WalletInfo } from './components/walletInfo';
import { CheckoutButton } from './components/checkoutButton';
import { selectBuilding } from 'store/building/selectors';
import { configSelector } from 'store/config/selectors';
import { selectReloadUrl, selectWallets, selectGetWalletStatus, selectWalletError } from 'store/wallets/selectors';
import { Navigate } from 'react-router-dom';
import { LoadingPage } from 'pages/loading';
import { flushPendo, trackPendoEvent } from 'utils/pendo';
import { EmptyWallet } from 'pages/wallet/components/emptyWallet';
import { authState } from 'store/auth/selectors';
import { useThemeFont } from 'hooks/use-theme-font.hook';
import { PageTitle } from '@hqo/react-components-library/dist/page-header';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as gecinaLogo from 'assets/youfirst-bureau-logo.png';

export const WalletPage = (): JSX.Element => {
  const building = useSelector(selectBuilding);
  const dispatch = useDispatch();
  const { loading: isAuthUrlLoading } = useSelector(authState);
  const { locale } = useSelector(configSelector);
  const [reloadAmount, setReloadAmount] = useState<number>();
  const { wallets } = useSelector(selectWallets);
  const error = useSelector(selectWalletError);
  const walletLoadingStatus = useSelector(selectGetWalletStatus);
  const wallet = wallets[0];
  const reloadUrl = useSelector(selectReloadUrl);
  const intl = useIntl();
  const themeFontTitle = useThemeFont(FontLocation.TITLE);
  const ldFlags = useFlags();

  const checkoutRedirect = useCallback(() => {
    trackPendoEvent('WALLET_RELOAD_CHECKOUT', {
      amount: reloadAmount,
      currency: wallet?.currency || CurrencyEnum.EUR,
    });

    dispatch(
      reloadWallet.request({
        amount: reloadAmount,
        buildingUuid: building.uuid,
        returnUri: window.location.origin,
        walletType: WALLET_TYPES.SODEXO,
      }),
    );
  }, [reloadAmount, wallet?.currency]);

  const setLogoUrl = useCallback(() => {
    const showAlternateLogo = ldFlags[LDFlags.SHOW_ALTERNATE_WALLET_LOGO];

    if (showAlternateLogo) {
      return undefined;
    }

    return gecinaLogo.default;
  }, [ldFlags]);

  useEffect(() => {
    if (wallets.length === 0 && error === null && walletLoadingStatus !== ACTION_STATUSES.FULFILLED && building?.uuid)
      dispatch(getWallets.request({ buildingUuid: building.uuid, walletTypes: [WALLET_TYPES.SODEXO] }));
  }, [wallets, building?.uuid]);

  useEffect(() => {
    if (reloadUrl) {
      trackPendoEvent('wallet-reload-redirect');
      flushPendo().then(() => {
        window.location.href = reloadUrl;
      });
    }
  }, [reloadUrl]);

  if (error && error?.data?.code !== EXTERNAL_AUTH_TOKEN_NOT_FOUND) {
    return <Navigate to={ERROR_PATH} />;
  }
  if (walletLoadingStatus !== ACTION_STATUSES.FULFILLED || isAuthUrlLoading) {
    return <LoadingPage />;
  }
  if (walletLoadingStatus === ACTION_STATUSES.FULFILLED && !wallet) {
    return (
      <EmptyWallet
        title={intl.formatMessage({ id: 'no_wallet_title' })}
        body={intl.formatMessage({ id: 'no_wallet_body' })}
        errorIcon={EMPTY_WALLET}
      />
    );
  }
  return (
    <Container>
      <WalletContainer>
        <PageTitle font={themeFontTitle}>
          <FormattedMessage id="recharge_my_badge" />
        </PageTitle>
        <WalletInfo
          balance={wallet.balance}
          currency={wallet.currency}
          locale={LocaleEnum[locale as keyof typeof LocaleEnum]}
          logoUrl={setLogoUrl()}
          walletNumber={wallet.wallet_id}
        />
        <ReloadTitle>
          <FormattedMessage id="amount_to_recharge" />
        </ReloadTitle>
        {RELOAD_AMOUNTS.map((amount) => {
          return (
            <ReloadButton
              amount={amount}
              currency={wallet.currency || CurrencyEnum.EUR}
              locale={LocaleEnum[locale as keyof typeof LocaleEnum]}
              key={amount}
              onClick={setReloadAmount}
              selected={reloadAmount === amount}
            />
          );
        })}
      </WalletContainer>
      <CheckoutButtonWrapper>
        <CheckoutButton disabled={!reloadAmount} onClick={checkoutRedirect} />
      </CheckoutButtonWrapper>
    </Container>
  );
};
