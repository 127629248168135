import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from 'components/root';
import reportWebVitals from './reportWebVitals';

import { withLDProvider } from 'launchdarkly-react-client-sdk';

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_TENANT_LAUNCH_DARKLY_CLIENT_ID,
  user: {
    anonymous: true,
  },
})(Root);

ReactDOM.render(
  <React.StrictMode>
    <LDProvider />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
