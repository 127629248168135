import React from 'react';
import { Container } from 'hocs/shared-styles';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';

export const LoadingPage = (): JSX.Element => (
  <Container data-testid="loading-container" isLoading>
    <Spinner size="5em" color={defaultTheme.colors.$greyDark} />
  </Container>
);
