import { RootState } from '../reducer';
import { WalletsState } from 'store/wallets/types';
import { createSelector } from 'reselect';

export const selectWallets = (state: RootState): WalletsState => state.wallets;

export const selectWalletError = createSelector([selectWallets], (wallets) => wallets.getWallets.error);

export const selectReloadUrl = createSelector(selectWallets, (state: WalletsState): string => state.reloadUrl);

export const selectGetWalletStatus = createSelector([selectWallets], (wallets) => wallets.getWallets.status);
