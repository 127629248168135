import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BalanceAmount, BottomRow, WalletNumber, BalanceTitle, Logo, WalletInfoCard } from './styles';
import { formatCurrency } from 'utils/formatCurrency';
import { LocaleEnum } from 'shared/consts';

interface WalletInfoProps {
  balance: number;
  currency: string;
  locale: LocaleEnum;
  logoUrl: string;
  walletNumber: string;
}

export const WalletInfo = ({ balance, walletNumber, currency, locale, logoUrl }: WalletInfoProps): JSX.Element => {
  return (
    <WalletInfoCard data-testid="wallet-info-card">
      <BalanceTitle>
        <FormattedMessage id="badge_balance" />
      </BalanceTitle>
      <BalanceAmount data-testid="wallet-balance-amount" data-pendoid="wallet-balance">
        {formatCurrency(balance, currency, locale)}
      </BalanceAmount>
      <BottomRow>
        <WalletNumber>{walletNumber}</WalletNumber>
        <Logo logoUrl={logoUrl} data-testid="wallet-logo" />
      </BottomRow>
    </WalletInfoCard>
  );
};
