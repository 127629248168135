import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';
import { ACTION_STATUSES } from 'shared/consts';

import { WalletsState } from './types';

export const INITIAL_STATE: WalletsState = {
  wallets: [],
  getWallets: {
    status: null,
    error: null,
  },
  reloadUrl: null,
};

const getWalletsRequestHandler = (state: WalletsState): WalletsState => ({
  ...state,
  getWallets: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getWalletsSuccessHandler = (
  state: WalletsState,
  { payload }: ActionType<typeof actions.getWallets.success>,
): WalletsState => ({
  ...state,
  wallets: payload,
  getWallets: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getWalletsFailureHandler = (
  state: WalletsState,
  { payload: error }: ActionType<typeof actions.getWallets.failure>,
): WalletsState => ({
  ...state,
  getWallets: {
    status: ACTION_STATUSES.REJECTED,
    error,
  },
});

const reloadWalletRequestHandler = (state: WalletsState): WalletsState => ({
  ...state,
  getWallets: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const reloadWalletSuccessHandler = (
  state: WalletsState,
  { payload }: ActionType<typeof actions.reloadWallet.success>,
): WalletsState => ({
  ...state,
  reloadUrl: payload.reload_url,
  getWallets: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const reloadWalletFailureHandler = (
  state: WalletsState,
  { payload: error }: ActionType<typeof actions.reloadWallet.failure>,
): WalletsState => ({
  ...state,
  getWallets: {
    status: ACTION_STATUSES.REJECTED,
    error,
  },
});

const WalletsReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getWallets.request, getWalletsRequestHandler)
  .handleAction(actions.getWallets.success, getWalletsSuccessHandler)
  .handleAction(actions.getWallets.failure, getWalletsFailureHandler)
  .handleAction(actions.reloadWallet.request, reloadWalletRequestHandler)
  .handleAction(actions.reloadWallet.success, reloadWalletSuccessHandler)
  .handleAction(actions.reloadWallet.failure, reloadWalletFailureHandler);

export default WalletsReducer;
