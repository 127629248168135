import { DefaultThemeWithFonts, AppThemeProvider } from '@hqo/react-components-library/dist/molecules/theme';

import React from 'react';
import { useSelector } from 'react-redux';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { CustomFonts } from 'components/custom-fonts';
import { ACTION_STATUSES } from 'shared/consts';
import { useBuilding } from 'hooks/use-building.hook';
import { selectBuildingStatus } from 'store/building/selectors';
import { AppContentWrapperStyles } from './app-content-wrapper.styles';
import { LoadingPage } from 'pages/loading';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const buildingStatus = useSelector(selectBuildingStatus);
  const theme = useBuildingTheme();
  useBuilding();

  const isDataLoading = !theme || !buildingStatus || buildingStatus === ACTION_STATUSES.PENDING;

  return isDataLoading ? (
    <LoadingPage />
  ) : (
    <>
      <AppContentWrapperStyles customThemeFonts={theme.fonts} />
      <CustomFonts />
      <AppThemeProvider theme={theme as DefaultThemeWithFonts}>{children}</AppThemeProvider>
    </>
  );
};
