import styled from 'styled-components';
import { TINY_DEVICE_MAX_WIDTH } from 'shared/consts';

export const WalletInfoCard = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  color: #fff;
  min-height: 129px;
  overflow: hidden;
  padding: 22px 20px;
`;

export const BalanceTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const BalanceAmount = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 43px;
  margin-top: 5px;
`;

export const BottomRow = styled.div`
  height: 42px;
  width: 100%;
`;

export const WalletNumber = styled.div`
  display: inline;
  float: left;
  font-size: 14px;
  margin-top: 20px;

  @media only screen and (max-width: ${TINY_DEVICE_MAX_WIDTH}px) {
    width: 100%;
  }
`;

export const Logo = styled.div<{ logoUrl: string }>`
  background: url(${(props) => props.logoUrl}) center/contain no-repeat;
  display: inline;
  float: right;
  height: 100%;
  width: 30%;

  @media only screen and (max-width: ${TINY_DEVICE_MAX_WIDTH}px) {
    float: left;
  }
`;
