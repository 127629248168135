import { LocaleEnum } from 'shared/consts';

export const formatCurrency = (currencyValue: number, currencyType: string, locale?: LocaleEnum): string => {
  return new Intl.NumberFormat(locale || LocaleEnum['en-US'], {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(currencyValue);
};
