import React, { useCallback } from 'react';
import { BUTTON_VARIANT } from '@hqo/react-components-library/dist/utils';
import { useIntl } from 'react-intl';
import { StyledCheckoutButton } from './styles';

interface CheckoutButtonProps {
  disabled: boolean;
  onClick?: VoidFunction;
}

const CheckoutButtonComponent = ({ disabled, onClick }: CheckoutButtonProps): JSX.Element => {
  const intl = useIntl();
  const buttonText = intl.formatMessage({ id: 'continue_to_checkout' });
  const clickHandler = useCallback(() => onClick(), [onClick]);
  return (
    <StyledCheckoutButton
      data-cy="checkout-button"
      data-testid="checkout-button"
      data-pendoid="checkout-button"
      variant={BUTTON_VARIANT.PRIMARY}
      text={buttonText}
      disabled={disabled}
      size="md"
      onClick={clickHandler}
    />
  );
};

export const CheckoutButton = CheckoutButtonComponent;
