import { ERROR_PATH, ROOT_PATH, WALLET_PATH } from 'shared/consts';
import { Route, Routes } from 'react-router-dom';
import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import { AppRoot } from 'components/app-root';
import React from 'react';
import { Private } from 'components/router/private';
import { WalletPage } from 'pages/wallet';
import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';

export const Router: React.FC = (): JSX.Element => (
  <Routes>
    <Route
      path={ROOT_PATH}
      element={
        <Private>
          <AppContentWrapper>
            <WalletPage />
          </AppContentWrapper>
        </Private>
      }
    />
    <Route path={WALLET_PATH} element={<AppRoot />} />
    <Route path="/" element={<AppRoot />} />
    <Route path={ERROR_PATH} element={<ErrorPage />} />
    <Route element={<DefaultRedirect />} />
  </Routes>
);
