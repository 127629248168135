import * as altBuildingsActions from 'store/alt-buildings/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';

import { concatMap, filter, mergeMap } from 'rxjs/operators';
import { showGenericErrorNotification, showErrorNotification } from './actions';

import { ERROR_PATH, FORBIDDEN_ERROR_CODE, EXTERNAL_AUTH_TOKEN_NOT_FOUND } from 'shared/consts';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { getWallets, reloadWallet } from 'store/wallets/actions';
import { openAuthFlow } from 'store/auth/actions';
import { replace } from 'store/router/actions';

export const handleAuthErrors: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    // Add failure actions to trigger error modal here and in the test file
    filter(isActionOf([getWallets.failure, reloadWallet.failure])),
    mergeMap((action) => {
      if (
        action?.payload?.status_code === FORBIDDEN_ERROR_CODE &&
        action?.payload?.data?.code === EXTERNAL_AUTH_TOKEN_NOT_FOUND
      ) {
        return of(openAuthFlow.request());
      }

      return of(showErrorNotification({ action: action.type, error: action?.payload?.error }));
    }),
  );

export const handleGenericErrors: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    // Add failure actions to trigger error page redirects here and in the test file
    filter(
      isActionOf([
        themeActions.getBuildingTheme.failure,
        userActions.externalLogin.failure,
        userActions.getCurrentUser.failure,
        altBuildingsActions.getAltBuildings.failure,
      ]),
    ),
    concatMap((action) =>
      of(
        showGenericErrorNotification({
          action: action.type,
          error: action.payload.error,
        }),
        replace(ERROR_PATH),
      ),
    ),
  );
