import { AltBuildingsState } from '../alt-buildings/types';
import { ApiClient } from './api-client';
import { ConfigState } from 'store/config/types';
import { UserState } from 'store/user/types';

interface ApiClientState {
  user: Pick<UserState, 'token'>;
  config: ConfigState;
  altBuildings?: Pick<AltBuildingsState, 'currentAltBuilding'>;
}

export const apiClient = (
  state: ApiClientState,
  baseUrlOverride?: string | null,
  authTokenOverride?: string | null,
  altBuildingUuidOverride?: string | null,
): ApiClient => {
  const baseUrl = baseUrlOverride ?? state.config.apiUrl;
  const authToken = authTokenOverride ?? state.user.token;
  const { buildingUuid } = state.config;
  const altBuildingUuid = altBuildingUuidOverride ?? state.altBuildings?.currentAltBuilding?.alt_building_uuid;

  return new ApiClient(baseUrl, authToken, buildingUuid, altBuildingUuid);
};
