import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';

interface ButtonProps {
  selected: boolean;
}

export const AmountButton = styled(Button)<ButtonProps>`
  border: ${({ selected }) => (selected ? '2px solid #00aca9' : 'none')};
  border-radius: 12px;
  box-shadow: 0px 2px 10px rgba(54, 54, 54, 0.15);
  color: #122941;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 18px;
  margin-top: 18px;
  padding: 20px 0px;
  width: 100%;

  &:hover:not([disabled]) {
    background-color: #fff;
  }
`;
