import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

import { App } from 'components/app';
// https://reactrouter.com/docs/en/v6/api#unstable_historyrouter
// This API is currently prefixed as unstable_ because you may unintentionally add two versions of the history
// library to your app, the one you have added to your package.json and whatever version React Router uses internally.
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { HqoThemeProvider } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { IntlProvider } from 'components/intl-provider';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { history } from 'store/router/history';
import { store } from 'store';
import { faAddressCard, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faAddressCard, faExclamationCircle);

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <IntlProvider>
      <HqoThemeProvider>
        <AppThemeProvider theme={defaultTheme}>
          <Router history={history}>
            <App />
          </Router>
        </AppThemeProvider>
      </HqoThemeProvider>
    </IntlProvider>
  </ReduxProvider>
);
