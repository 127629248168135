import React, { useCallback } from 'react';

import { BUTTON_VARIANT } from '@hqo/react-components-library/dist/utils';
import { formatCurrency } from 'utils/formatCurrency';
import { LocaleEnum } from 'shared/consts';
import { AmountButton } from './styles';

interface ReloadButtonProps {
  amount: number;
  currency: string;
  locale: LocaleEnum;
  onClick?: React.Dispatch<React.SetStateAction<number>>;
  selected: boolean;
}

const ReloadButtonComponent = ({ amount, currency, locale, onClick, selected }: ReloadButtonProps): JSX.Element => {
  const clickHandler = useCallback(() => {
    onClick(amount);
  }, []);

  const text = formatCurrency(amount, currency, locale);
  return (
    <AmountButton
      data-cy="amount-button"
      data-testid="amount-button"
      data-pendoid="amount-button"
      data-pendostate={selected ? 'selected' : ''}
      data-pendovalue={text}
      onClick={clickHandler}
      selected={selected}
      size="lg"
      variant={BUTTON_VARIANT.SECONDARY}
      text={text}
    />
  );
};

export const ReloadButton = React.memo(ReloadButtonComponent);
