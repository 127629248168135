import * as actions from './actions';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { ReloadWalletAPIResponse, WalletsAPIResponse } from './types';

export const getWalletsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getWallets.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { buildingUuid, walletTypes } = payload;
      return apiClient(state)
        .getWallets(buildingUuid, walletTypes)
        .pipe(
          map(({ response }: AjaxResponse<WalletsAPIResponse>) => {
            return actions.getWallets.success(response.data);
          }),
          catchError(({ response }) => {
            return of(actions.getWallets.failure(response));
          }),
        );
    }),
  );

export const reloadWalletEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.reloadWallet.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .reloadWallet(payload)
        .pipe(
          map(({ response }: AjaxResponse<ReloadWalletAPIResponse>) => {
            return actions.reloadWallet.success(response.data);
          }),
          catchError(({ response }) => {
            return of(actions.reloadWallet.failure(response));
          }),
        );
    }),
  );
