import React from 'react';
import { Router } from 'components/router';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { useLaunchDarklyIdentify } from 'hooks/use-launch-darkly-identify.hook';
import { usePendo } from 'hooks/use-pendo.hook';
import { LoadingPage } from 'pages/loading';

export const App: React.FC = (): JSX.Element => {
  const shouldWaitForLogin = useExternalLogin();
  useLaunchDarklyIdentify();
  usePendo();

  if (shouldWaitForLogin) {
    return <LoadingPage />;
  }

  return <Router />;
};
