import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyWallet } from 'pages/wallet/components/emptyWallet';
import { ERROR_OCCURRED } from 'shared/consts';

export const ErrorPage = (): JSX.Element => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'reload_error_title' });
  const body = intl.formatMessage({ id: 'reload_error_body' });
  return <EmptyWallet title={title} body={body} errorIcon={ERROR_OCCURRED} />;
};
