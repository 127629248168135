import * as actions from './actions';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { of, EMPTY } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { AuthURLResponse } from './types';
import { UiMetadata } from 'store/ui-metadata/types';
import { OwnerTypesEnum } from 'shared/consts';
import { getFlushPendoObservable } from 'utils/pendo';

export const openAuthFlowEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.openAuthFlow.request)),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      return apiClient(state)
        .fetchUiMetadata({ ownerType: OwnerTypesEnum.BUILDING.toUpperCase(), ownerId: state.building.building.uuid })
        .pipe(
          switchMap(({ response: uiMetadataResponse }: AjaxResponse<UiMetadata>) => {
            return apiClient(state)
              .getAuthURL(uiMetadataResponse.ui_metadata.helix_auth_link, state.building.building.uuid)
              .pipe(
                switchMap(({ response: authURLResponse }: AjaxResponse<AuthURLResponse>) =>
                  getFlushPendoObservable().pipe(
                    map(() => actions.openAuthFlow.success(authURLResponse.url)),
                    catchError(() => of(actions.openAuthFlow.success(authURLResponse.url))),
                  ),
                ),
                catchError((error: Error) => of(actions.openAuthFlow.failure({ error }))),
              );
          }),
          catchError((error: Error) => of(actions.openAuthFlow.failure({ error }))),
        );
    }),
  );

export const redirectToAuthUrlEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.openAuthFlow.success)),
    switchMap((action) => {
      window.location.href = action.payload as string;

      return EMPTY;
    }),
  );
