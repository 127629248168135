import { LAUNCH_DARKLY_ANONYMOUS_EMAIL } from 'shared/consts';
import { useEffect } from 'react';
import { selectBuilding } from 'store/building/selectors';
import { currentUser } from 'store/user/selectors';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { LDUser } from 'launchdarkly-js-client-sdk';

const initialLDUser: LDUser = {
  key: LAUNCH_DARKLY_ANONYMOUS_EMAIL,
  anonymous: true,
};

export const useLaunchDarklyIdentify = (): void => {
  const ldClient = useLDClient();
  const user = useSelector(currentUser);
  const building = useSelector(selectBuilding);

  useEffect(() => {
    if (!user || !ldClient) return;
    ldClient.identify({
      ...initialLDUser,
      email: user.email,
      key: user.email,
      anonymous: false,
      custom: {
        portfolio_uuid: building?.portfolio_uuid,
      },
    });
  }, [ldClient, user, building]);
};
