import { usePendoAnalytics } from 'hooks/use-pendo-analytics.hook';
import { useEffect } from 'react';
import { initializePendo } from 'utils/pendo';

export const usePendo = (): void => {
  const analytics = usePendoAnalytics();

  useEffect(() => {
    if (window.pendo && analytics) {
      initializePendo(window.pendo, analytics);
    }
  }, [analytics]);
};
