import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { StateType } from 'typesafe-actions';
import { RootAction } from 'store/actions';
import { composeEnhancers } from 'store/utils';
import rootEpic from './epics';
import rootReducer, { RootState } from './reducer';
import { rootDependencies, RootDependencies } from 'store/dependencies';

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, RootDependencies>({
  dependencies: rootDependencies,
});
const enhancer = composeEnhancers(applyMiddleware(epicMiddleware));
const initialState = {};
const store = createStore(rootReducer, initialState, enhancer);

epicMiddleware.run(rootEpic);

export type Store = StateType<typeof store>;
export default store;
