import { getBuildingTheme } from 'store/theme/actions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBrandTheme } from 'store/theme/selectors';
import { defaultTheme, DefaultThemeWithFonts } from '@hqo/react-components-library/dist/molecules/theme';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { configSelector } from 'store/config/selectors';

export const useBuildingTheme = (): DefaultThemeWithFonts => {
  const dispatch = useDispatch();
  const brandTheme = useSelector(selectBrandTheme);
  const { buildingUuid } = useSelector(configSelector);

  useEffect(() => {
    if (buildingUuid) {
      dispatch(getBuildingTheme.request(buildingUuid as string));
    }
  }, [dispatch, getBuildingTheme, buildingUuid]);

  return useMemo(() => {
    if (brandTheme) {
      return {
        colors: {
          ...defaultTheme.colors,
          $primary: brandTheme.primary_color,
          $secondary: brandTheme.secondary_color,
          primary: brandTheme.primary_color,
          secondary: brandTheme.secondary_color,
        },
        fonts: brandTheme.font?.body?.font_family ? [`${brandTheme.font?.body?.font_family}`, ...hqoTheme.fonts] : null,
      };
    }
    return defaultTheme;
  }, [brandTheme, defaultTheme, buildingUuid]);
};
