import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';

interface ButtonProps {
  disabled: boolean;
}
export const StyledCheckoutButton = styled(Button)<ButtonProps>`
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.greys.adminBlack : theme.colors.primary)};
  border-color: transparent;
  font-size: 20px;
  font-weight: 600;
  margin-top: 18px;
  padding: 14px;
  width: 100%;
  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
